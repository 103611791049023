<template>
  <!-- Generated using model-view-update.mustache -->
  <div class="update-entity">
    <div class="edit-page__header" v-if="isReady">
      <div class="wrapper_edit_forms content d-flex justify-content-between">
        <div class="zq-create-page-title-wrapper">
          <h3 class="zq-child-title-text zq-edit-title">{{ texts.editPage.title }}</h3>
        </div>
        <ActionsForEdit
          @isShowDropdown="isShowDropdown"
          :isShowUpdate="false"
        />
      </div>
      <div class="tabs_wrapper">
        <div
          @click="showTab('settings')"
          :class="[{active: tabName === 'settings', notSaved:  !isSettingsDataSaved}, tabClasses]"
        >
          Settings
          <div class="edit-tooltip-wrapper" v-if="!isSettingsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div
              class="tooltip-old bs-tooltip-old-auto fade show"
              role="tooltip"
              aria-hidden="true"
              x-placement="right"
            >
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="ruleModels.includes(model)"
          @click="showTab('rules')"
          :class="[{active: tabName === 'rules', notSaved:  !isRulesDataSaved}, tabClasses]"
        >
          Rules
          <div class="edit-tooltip-wrapper" v-if="!isRulesDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="rewardModels.includes(model)"
          @click="showTab('rewards')"
          :class="[{active: tabName === 'rewards', notSaved:  !isRewardsDataSaved}, tabClasses]"
        >
          Rewards
          <div class="edit-tooltip-wrapper" v-if="!isRewardsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="translatableFields.length"
          @click="showTab('translations')"
          :class="[{active: tabName === 'translations', notSaved:  !isTranslationsDataSaved}, tabClasses]"
        >
          Translations
          <div class="edit-tooltip-wrapper" v-if="!isTranslationsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-content content position-relative">
      <div v-if="tabName === 'settings' && isReady">
        <CCardHeader>
          <div class="mb-4 d-flex justify-content-between align-items-center">
            <strong class="title">Edit Settings</strong>
            <div class="message" v-if="!isSettingsDataSaved">Data not saved!</div>
            <CButton
              class="action-create-button zq--responsive-button__common"
              type="submit"
              @click="updateSettings"
            >
              Update Settings
            </CButton>
          </div>
        </CCardHeader>
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="settingsCollapsed = !settingsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Settings</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="settingsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="settingsCollapsed" :duration="400">
                <CCardBody>
                  <CForm
                    ref="updateCompetition"
                    v-on:submit.prevent="updateCompetition"
                    novalidate
                    class="update-competition-form"
                  >
                    <FormBuilder
                      :list="formList"
                      :isCreateHeader="false"
                      :isHideDisabled="true"
                      @updated="updateFormData"
                      :page="{
                        title: texts.editPage.title,
                        info: descriptions,
                        mode: 'update',
                      }"
                    />
                  </CForm>
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow v-if="dependantOnData">
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="dependantOnCollapsed = !dependantOnCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit DependantOn</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="dependantOnCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="dependantOnCollapsed" :duration="400">
                <CCardBody>
                  <EditDependantOn
                    :dependantOnData="dependantOnData"
                    :isValid="true"
                    @updateDependantOnData="updateDependantOnData"
                  />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow  v-if="schedulingData">
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="schedulingCollapsed = !schedulingCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Scheduling</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="schedulingCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="schedulingCollapsed" :duration="400">
                <CCardBody>
                  <CreateCompetitionScheduling
                    @updateSchedulingData="getSchedulingData"
                    @updateConstraints="getConstraints"
                    @resetSchedulingValidate="resetSchedulingValidate"
                    @updateSchedulingSwitch="updateSchedulingSwitch"
                    :constraintsData="constraints"
                    :schedulingData="schedulingData"
                    :isStartDate="schedulingDataValid_scheduledStartDate"
                    :isEndDate="schedulingDataValid_scheduledEndDate"
                    :isStrategy="schedulingDataValid_scoringStrategy"
                    :status="status"
                    creationType="advanced"
                    :descriptions="schedulingDescriptions"
                  />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow  v-if="entrantsData && isReady">
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="entrantsCollapsed = !entrantsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Eligible members</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="entrantsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="entrantsCollapsed" :duration="400">
                <CCardBody>
                  <CreateEntrants
                    @updateEntrantsData="getEntrantsData"
                    @updateConstraints="getConstraints"
                    @updateLimitParticipants="updateLimitParticipants"
                    @updateCanParticipate="updateCanParticipate"
                    @resetEntrantsValidation="resetEntrantsValidation"
                    @updateOptIn="updateOptIn"
                    :isValid="entrantsOnDataValidate"
                    :entrantsData="entrantsData"
                    :constraintsData="constraints"
                    :status="status"
                    :descriptions="entrantsDescriptions"
                  />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow  v-if="productsData">
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="productsCollapsed = !productsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Products</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="productsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="productsCollapsed" :duration="400">
                <CCardBody>
                  <AddProducts
                    @updateIsAllProductsInclude="updateIsAllProductsInclude"
                    @updateProductsData="getProductsData"
                    @updateCurrentTypes="updateProductsCurrentTypes"
                    :isValid="productsDataValidate"
                    :productsData="productsData"
                    :status="status"
                    :descriptions="productsDescriptions"
                  />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <PreviewSpiner v-if="!isReady" />
      <EditRules
        v-if="ruleModels.includes(model) && tabName === 'rules'"
        :entityId="entityId"
        :context="'competition'"
        :notSavedRulesData="notSavedRulesData"
        @isRulesDataSaved="setRulesDataSaved"
        @notSavedRulesData="setNotSavedRulesData"
      />
      <EditRewards
        v-if="rewardModels.includes(model) && tabName === 'rewards'"
        :entityId="entityId"
        :entityType="'Competition'"
        :notSavedTranslationsData="notSavedTranslationsData"
        :notSavedRewardsData="notSavedRewardsData"
        @isRewardsDataSaved="setRewardsDataSaved"
        @notSavedRewardsData="setNotSavedRewardsData"
      />
      <EditTranslations
        ref="editTranslations"
        v-if="modelFields.baseFields.translatableFields.length > 0 && tabName === 'translations'"
        :entityData="formData"
        :entityType="'Competition'"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        :notSavedTranslationsData="notSavedTranslationsData"
        @isTranslationsDataSaved="setTranslationsDataSaved"
        @notSavedTranslationsData="setNotSavedTranslationsData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from "@/config";
import { competitions } from '@/config/descriptions/competitions';
import fieldHelpers from '@/utils/ZiqniFieldHelper.js';
import { competitionsTexts } from '@/config/pageTexts/competitions.json';
import competitionFields from '@/generated/ziqni/store/modules/competitions/fields';
import EditRules from '@/shared/components/supportModels/rules/EditRules';
import CreateCompetitionScheduling from '@/shared/components/supportModels/scheduling/CreateCompetitionScheduling';
import CreateEntrants from '@/shared/components/supportModels/entrants/CreateEntrants';
import AddProducts from '@/shared/components/supportModels/products/AddProducts';
import EditRewards from '@/shared/components/supportModels/rewards/EditRewards';
import EditTranslations from '@/shared/components/supportModels/translations/EditTranslations';
import EditDependantOn from '@/shared/components/supportModels/dependantOn/EditDependantOn';
import ActionsForEdit from '@/shared/components/ActionsForEdit';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import PreviewSpiner from '@/shared/UI/Spiner';

export default {
  name: 'EditCompetition',
  components: {
    EditTranslations,
    ActionsForEdit,
    EditRules,
    CreateCompetitionScheduling,
    CreateEntrants,
    AddProducts,
    EditRewards,
    EditDependantOn,
    ClCardArrow,
    PreviewSpiner,
  },
  data() {
    return {
      model: 'competition',
      entityId: this.$route.params.id,
      entityName: '',
      descriptions: {
        ...competitions.edit
      },
      texts: {
        ...competitionsTexts
      },
      isShowDropdown: false,
      tabName: "settings",
      tabClasses: "btn edit-tab",
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      showCMetaInputs: false,
      showCAdjustmentFactorsInputs: false,
      formData: null,
      accountType: [],
      options: ["Slots", "Casino", "Hockey"],
      idValidate: null,
      nameValidate: null,
      typeValidate: null,
      page: pageConfig.page,
      formList: [],
      translatableFields: [],
      translationsData: {},
      modelFields : {
        ...competitionFields,
      },
      schedulingData: null,
      entrantsData: {
        selectedData: [],
        limitParticipants: false,
        canParticipate: true,
        optinRequiredForEntrants: false,
        formData: {
          maxNumberOfEntrants: null,
          minNumberOfEntrants: 0,
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        }
      },
      productsData: {
        currentTypes: {},
        isAllProductsInclude: true,
        selectedData: [],
        formData: {
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: [],
          },
          must: [],
          mustNot: [],
          should: []
        },
      },
      schedulingDataValid_scheduledStartDate: null,
      schedulingDataValid_scheduledEndDate: null,
      schedulingDataValid_scoringStrategy: null,
      productsDataValidate: null,
      constraints: [],
      status: 'Cancel',
      entrantsOnDataValidate: null,
      dependantOnData: null,
      rewardModels: ['achievement', 'competition'],
      ruleModels: ['achievement', 'competition'],
      settingsCollapsed: false,
      dependantOnCollapsed: false,
      schedulingCollapsed: false,
      entrantsCollapsed: false,
      productsCollapsed: false,
      isReady: false,
      isSettingsDataSaved: true,
      isRulesDataSaved: true,
      isRewardsDataSaved: true,
      isTranslationsDataSaved: true,
      notSavedText: 'Data not saved!',
      notSavedRulesData: {},
      notSavedTranslationsData: {},
      notSavedRewardsData: {},
    }
  },
  computed: {
    ...mapGetters('competitions', ['success', 'message', 'competitions', 'competition']),
    ...mapGetters('actionTypes', ['success', 'message', 'actionType', 'actionTypes']),
    ...mapGetters('theme', ['theme']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    created() {
      return dateFormate(this.formData.created)
    },
    schedulingDescriptions() {
      return {
        ...competitions.edit.competition.scheduling,
      }
    },
    entrantsDescriptions() {
      return {
        ...competitions.edit.competition.entrants,
      }
    },
    productsDescriptions() {
      return {
        ...competitions.edit.competition.products.products,
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('competitions', [
      'handleGetCompetitions_item',
      'handleUpdateCompetitions'
    ]),
    ...mapActions('actionTypes', ['handleGetActionTypes_item', 'handleGetActionTypes']),
    ...mapActions('tags', ['handleGetTagsByQuery']),
    initialize() {
      this.handleGetActionTypes([]);
      this.handleGetCompetitions_item([this.$route.params.id],1,0)
        .then(async data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name
          });

          let actionTypesIds = []
          if (data[0].actionTypeAdjustmentFactors) {
            actionTypesIds = data[0].actionTypeAdjustmentFactors.map((item) => {
              return item.actionTypeId
            })
          }

          this.query = actionTypesIds.length ? {"id": actionTypesIds.join()} : []; // just query for the ids!

          this.handleGetActionTypes_item(actionTypesIds);
          this.formList = fieldHelpers.prepareEditFormList(
            competitionFields,
            this.texts.editPage,
            this.descriptions,
            this.competition
          );

          this.formList = this.formList.filter(item => !['entrantMemberType'].includes(item.key));

          this.status = data[0].status;

          this.schedulingData = {
            autoStart: data[0].constraints.includes('autoStart'),
            autoStop: data[0].constraints.includes('autoStop'),
            numberOfRounds: data[0].numberOfRounds,
            scheduledStartDate: data[0].scheduledStartDate,
            scheduledEndDate: data[0].scheduledEndDate,
            strategies: {},
            defaultRanking: true,
            scoreDesc: false,
            scoreFirst: false,
            ignoreTime: false,
            ignoreScore: false,
            timeDesc: false,
          };

          this.entrantsData = {
            selectedData: [],
            limitParticipants: false,
            canParticipate: true,
            optinRequiredForEntrants: data[0].constraints.includes('optinRequiredForEntrants'),
            formData: {
              maxNumberOfEntrants: data[0].maxNumberOfEntrants ?? null,
              minNumberOfEntrants: data[0].minNumberOfEntrants ?? 0,
              shouldMatchAtLeast: data[0].entrantMemberTagsFilter.shouldMatchAtLeast ?? 1,
              dependantOn: {
                must: data[0].entrantMemberTagsFilter.must ?? [],
                mustNot: data[0].entrantMemberTagsFilter.mustNot ?? [],
                should: data[0].entrantMemberTagsFilter.should ?? []
              },
              must: data[0].entrantMemberTagsFilter.must ?? [],
              mustNot: data[0].entrantMemberTagsFilter.mustNot ?? [],
              should: data[0].entrantMemberTagsFilter.should ?? []
            },
            currentTypes: {},
          }

          const entrantsDependantTags = [
            ...this.entrantsData.formData.dependantOn.must,
            ...this.entrantsData.formData.dependantOn.mustNot,
            ...this.entrantsData.formData.dependantOn.should,
          ];

          if (entrantsDependantTags.length) {
            const tags = await this.handleGetTagsByQuery({
              queryRequest: {
                "must": [
                  {
                    "queryField": "key",
                    "queryValues": entrantsDependantTags
                  }
                ]
              }
            });
            this.entrantsData.canParticipate = false;
            this.entrantsData.selectedData = tags;

            let currentTypes = {};
            for (const key in this.entrantsData.formData.dependantOn) {
              if (this.entrantsData.formData.dependantOn[key] && this.entrantsData.formData.dependantOn[key].length) {
                this.entrantsData.formData.dependantOn[key].forEach(tag => {
                  currentTypes[tag] = key;
                })
              }
            }
            this.entrantsData.currentTypes = currentTypes;
          }

          if (typeof data[0].productTagsFilter === 'object' && Object.keys(data[0].productTagsFilter).length) {
            this.productsData.formData.dependantOn.must = data[0].productTagsFilter.must ?? [];
            this.productsData.formData.dependantOn.should = data[0].productTagsFilter.should ?? [];
            this.productsData.formData.dependantOn.mustNot = data[0].productTagsFilter.mustNot ?? [];

            const productTagsKeys = [
              ...this.productsData.formData.dependantOn.must,
              ...this.productsData.formData.dependantOn.should,
              ...this.productsData.formData.dependantOn.mustNot,
            ];

            const productTags = await this.handleGetTagsByQuery({
              queryRequest: {
                "must": [
                  {
                    "queryField": "key",
                    "queryValues": productTagsKeys
                  }
                ]
              }
            });
            let currentTypes = {};
            for (const key in data[0].productTagsFilter) {
              if (data[0].productTagsFilter[key] && data[0].productTagsFilter[key].length) {
                data[0].productTagsFilter[key].forEach(tag => {
                  currentTypes[tag] = key;
                })
              }
            }
            this.productsData = {
              isAllProductsInclude: data[0].constraints.includes('allProducts'),
              selectedData: productTags,
              formData: {
                shouldMatchAtLeast: data[0].productTagsFilter.shouldMatchAtLeast,
                dependantOn: {
                  must: data[0].productTagsFilter.must ?? [],
                  mustNot: data[0].productTagsFilter.mustNot ?? [],
                  should: data[0].productTagsFilter.should ?? []
                }
              },
              currentTypes: currentTypes,
            };
          } else {
            this.productsData = {
              isAllProductsInclude: data[0].constraints.includes('allProducts'),
              selectedData: data[0].products,
              formData: {
                shouldMatchAtLeast: null,
                dependantOn: {
                  must: [],
                  mustNot: [],
                  should: []
                }
              }
            };
          }

          this.isReady = true;
        });
      this.translatableFields = competitionFields.baseFields.translatableFields;
    },
    showTab(name) {
      this.tabName = name;
    },
    setRulesDataSaved(val) {
      this.isRulesDataSaved = val;
    },
    setRewardsDataSaved(val) {
      this.isRewardsDataSaved = val;
    },
    setTranslationsDataSaved(val) {
      this.isTranslationsDataSaved = val;
    },
    setNotSavedRulesData(obj) {
      this.notSavedRulesData = obj;
    },
    setNotSavedTranslationsData(obj) {
      this.notSavedTranslationsData = obj;
    },
    setNotSavedRewardsData(obj) {
      this.notSavedRewardsData = obj;
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
    updateDependantOnData(val) {
      if (!isEqual(this.dependantOnData.dependantOn, val.formData.dependantOn)) {
        let selectedIds = [];
        val.selectedData.forEach(item => selectedIds.push(item.id));
        this.dependantOnData.dependantOn = cloneDeep(val.formData.dependantOn);
        this.dependantOnData.dependantOn.must = this.dependantOnData.dependantOn.must.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.dependantOn.mustNot = this.dependantOnData.dependantOn.mustNot.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.dependantOn.should = this.dependantOnData.dependantOn.should.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.entityType = val.formData.entityType;
        this.isSettingsDataSaved = false;
      }
    },
    updateSchedulingData(val) {
      if (!isEqual(this.schedulingData, val)) {
        this.schedulingData = val;
        this.isSettingsDataSaved = false;
      }
    },
    getSchedulingData(val) {
      this.schedulingData = val;
    },
    getConstraints(val) {
      this.constraints = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_scheduledStartDate = null;
      this.schedulingDataValid_scheduledEndDate = null;
      this.schedulingDataValid_scoringStrategy = null;
    },
    updateSchedulingSwitch(field, val) {
      this.schedulingData[field] = val;
    },
    getEntrantsData(val) {
      this.entrantsData = val;
    },
    getEntrantMemberTagsFilter(formData) {
      if (this.entrantsData.canParticipate) {
        return {
          must: [],
          mustNot: [],
          should: [],
          shouldMatchAtLeast: 1,
        }
      }
      return {
        must: formData.must.length ? formData.must : null,
        mustNot: formData.mustNot.length ? formData.mustNot : null,
        should: formData.should.length ? formData.should : null,
        shouldMatchAtLeast: formData.shouldMatchAtLeast,
      }
    },
    updateLimitParticipants(val) {
      this.entrantsData.limitParticipants = val;
    },
    updateCanParticipate(val) {
      this.entrantsData.canParticipate = val;
    },
    updateOptIn(val) {
      this.entrantsData.optinRequiredForEntrants = val;
    },
    resetEntrantsValidation() {
      this.entrantsOnDataValidate = null;
    },
    getProductsData(val) {
      this.productsData = val;
    },
    updateProductsCurrentTypes(val) {
      this.productsData.currentTypes = val;
    },
    updateIsAllProductsInclude(val) {
      this.productsData.isAllProductsInclude = val;
    },
    getProductTagsFilterTagsFilter() {
      return {
        must: this.productsData.formData.dependantOn.must.length ? this.productsData.formData.dependantOn.must : null,
        mustNot: this.productsData.formData.dependantOn.mustNot.length ? this.productsData.formData.dependantOn.mustNot : null,
        should: this.productsData.formData.dependantOn.should.length ? this.productsData.formData.dependantOn.should : null,
        shouldMatchAtLeast: this.productsData.formData.dependantOn.shouldMatchAtLeast,
      }
    },
    async getProductIds() {
      let productIds = [];

      if (this.productsData.selectedData.length && Object.hasOwn(this.productsData.selectedData[0], 'productRefId')) {
        this.productsData.selectedData.forEach(product => {
          productIds.push(product.id);
        })
      }

      return productIds;
    },
    async updateSettings() {
      delete this.formData.statusCode;
      delete this.formData.status;
      delete this.formData.achievementLiveStatus;
      delete this.formData.spaceName;
      delete this.formData.created;
      delete this.formData.productRefId;
      delete this.formData.rewardType;
      delete this.formData.constraints;
      delete this.formData.memberRefId;
      delete this.formData.key;
      delete this.formData.actualEndDate;
      delete this.formData.actualStartDate;
      delete this.formData.products;
      delete this.formData.dependantOn;

      this.formData.scheduledStartDate = this.schedulingData.scheduledStartDate;
      this.formData.scheduledEndDate = this.schedulingData.scheduledEndDate;

      this.formData.maxNumberOfEntrants = this.formData.maxNumberOfEntrants ?? 9999;

      this.formData.constraints = this.constraints;

      this.formData.numberOfGroupStages = Number(this.formData.numberOfGroupStages) ?? 0;
      this.formData.tags = this.formData.tags ?? [];
      this.formData.numberOfRounds = Number(this.formData.numberOfRounds);

      // Products
      if (this.productsData.isAllProductsInclude) {
        if (!this.formData.addConstraints) this.formData.addConstraints = [];
        this.formData.addConstraints.push('allProducts');
      } else {
        if (!this.formData.removeConstraints) this.formData.removeConstraints = [];
        this.formData.removeConstraints.push('allProducts');
        this.formData.productIds = await this.getProductIds();
        if (!this.formData.productIds.length) {
          this.formData.productTagsFilter = this.getProductTagsFilterTagsFilter();
        } else {
          delete this.formData.productTagsFilter;
        }
      }

      // Entrants
      this.formData.minNumberOfEntrants = Number(this.entrantsData.formData.minNumberOfEntrants);
      this.formData.maxNumberOfEntrants = Number(this.entrantsData.formData.maxNumberOfEntrants);
      this.formData.entrantMemberType = this.formData.entrantMemberType ?? 'Individual';
      if (this.entrantsData.optinRequiredForEntrants) {
        if (!this.formData.addConstraints) this.formData.addConstraints = [];
        this.formData.addConstraints.push('optinRequiredForEntrants');
      } else {
        if (!this.formData.removeConstraints) this.formData.removeConstraints = [];
        this.formData.removeConstraints.push('allProducts');
      }
      this.formData.entrantMemberTagsFilter = this.getEntrantMemberTagsFilter(this.entrantsData.formData);
      if (!this.formData.banner) this.formData.banner = '';
      if (!this.formData.bannerHighResolution) this.formData.bannerHighResolution = '';
      if (!this.formData.bannerLowResolution) this.formData.bannerLowResolution = '';

      this.handleUpdateCompetitions({updateCompetitionRequest: this.formData})
        .then(() => {
          this.isSettingsDataSaved = true;
        })
    }
  },
  watch: {
    competition() {
      this.entityName = this.competition.name
    },
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.update-entity {
  height: 100%;
  .card-header {
    background-color: #f3f3f3;
    & ~ .row:first-of-type {
      margin-top: 1rem;
    }
  }
  .card {
    &.zq--wizard-card {
      background-color: var(--zq-sub-bg);
    }
  }
  .edit-tooltip-wrapper {
    display: inline-block;
    position: relative;
    .tooltip-old {
      position: absolute;
      visibility: hidden;
      top: -4px;
      left: 16px;
    }
  }
  .multiselect {
    &--disabled {
      .multiselect__single {
        background: none;
      }
    }
  }
  .fa-question-circle-o:hover ~ .tooltip-old {
    visibility: visible !important;
  }
  .message {
    color: var(--zq-warn);
  }
  .notSaved {
    color: var(--zq-warn) !important;
  }
  .form-content {
    border-top: 0;
  }
  .edit-page__header {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--zq-main-bg);
  }
  .wrapper_edit_forms {
    margin-top: 20px;
    display: flex;
  }
  .edit-tab {
    width: 50%;
    height: 35px;
    cursor: pointer !important;
    border-radius: 12px 12px 0 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #858585;
    text-align: left;
    &.active {
      border-bottom: solid 2px #007dfb;
    }
    &.btn {
      padding: 0.375rem 1rem;
      text-align: center;
    }
  }
  .tabs_wrapper {
    height: 100%;
    max-height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-radius: 12px 12px 0 0;
  }
  & > .form-content {border-top: none}
  .update-entity-form {
    height: calc(100% - 50px);
    input::placeholder {
      font-style: italic;
    }
    .card {
      background-color: var(--zq-sub-bg);
    }
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      .form-control.is-valid {
        padding-right: 15px;
      }
      .custom-multi-select .multiselect__select {
        opacity: 0;
      }
      .adjustment-factors-data {
        .fa-question-circle-o {
          top: 10px;
          right: -20px;
        }
      }
      .search-icon {
        position: absolute;
        width: 30px !important;
        height: 47px !important;
        right: 15px;
        top: 1px;
        padding: 4px 8px;
        text-align: center;
      }
      .editor {
        min-height: 200px;
        height: 200px;
      }
      .fa-question-circle-o {
        position: absolute;
        top: 0;
        right: -10px;
      }
      .meta-data {
        min-width: 70px;
        .fa-question-circle-o {
          top: 10px;
        }
      }
    }
  }
  @include media-breakpoint-up('768px') {
    .edit-page__header {
      padding: 0;
    }
    .wrapper_edit_forms {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: -8px;
    }
    .tabs_wrapper {
      margin: 0 3rem;
      min-width: 375px;
      width: 47%;
      max-height: 54px;
    }
    .edit-tab {
      height: 54px;
      line-height: 42px;
      cursor: pointer !important;
      &:hover {
        color: #858585;
      }
    }
  }
}
</style>
